import { ReactElement } from 'react';
import { IRoute } from 'interfaces/routesInt';
import urls from '../urls';
import ManageRoutes from 'routes/manageRoute';

const protectedPageElement: ReactElement = <ManageRoutes />;

const protectedRoutes: IRoute[] = [
  {
    path: urls.dashboard,
    element: protectedPageElement,
    isProtected: true,
    key: 'dashboard',
  },
  {
    path: urls.transactions,
    element: protectedPageElement,
    isProtected: true,
    key: 'transactions',
  },
  {
    path: urls.settings,
    element: protectedPageElement,
    isProtected: true,
    key: 'settings',
  },
  {
    path: urls.accounts,
    element: protectedPageElement,
    isProtected: true,
    key: 'accounts',
  },
  {
    path: urls.localTransfer,
    element: protectedPageElement,
    isProtected: true,
    key: 'local-transfer',
  },
  {
    path: urls.payTax,
    element: protectedPageElement,
    isProtected: true,
    key: 'pay-tax',
  },
  {
    path: urls.withdraw,
    element: protectedPageElement,
    isProtected: true,
    key: 'withdraw',
  },
  {
    path: urls.beneficiary,
    element: protectedPageElement,
    isProtected: true,
    key: 'beneficiary',
  },
  {
    path: urls.payBills,
    element: protectedPageElement,
    isProtected: true,
    key: 'pay-bills',
  },
  {
    path: urls.userProfile,
    element: protectedPageElement,
    isProtected: true,
    key: 'user-profile',
  },
  {
    path: urls.microloan,
    element: protectedPageElement,
    isProtected: true,
    key: 'microloan',
  },
  {
    path: urls.cards,
    element: protectedPageElement,
    isProtected: true,
    key: 'cards',
  },
  {
    path: urls.operators,
    element: protectedPageElement,
    isProtected: true,
    key: 'transfer-to-mno',
  },
  {
    path: urls.applyForLoan,
    element: protectedPageElement,
    isProtected: true,
    key: urls.applyForLoan,
  },
  {
    path: urls.cashWithdraw,
    element: protectedPageElement,
    isProtected: true,
    key: urls.cashWithdraw,
  },
  {
    path: urls.quickPay,
    element: protectedPageElement,
    isProtected: true,
    key: urls.quickPay,
  },
];

export default protectedRoutes;
