export default {
  landingPage: '/',
  login: '/login',
  register: '/register',
  dashboard: '/dashboard',
  remindUsername: '/remind-username',
  forgotPassword: '/forgot-password',
  transactions: '/transactions',
  settings: '/settings',
  accounts: '/accounts',
  localTransfer: '/transfer',
  transferBetweenOwnAccount: '/transfer-between-own-accounts',
  internationalTransfer: '/international-transfer',
  payTax: '/pay-tax',
  withdraw: '/withdraw',
  beneficiary: '/beneficiaries',
  payBills: '/pay-bills',
  userProfile: '/user-profile',
  microloan: '/microloan',
  cards: '/cards',
  operators: '/operators',
  applyForLoan: '/apply-for-loan',
  cashWithdraw: '/cash-withdraw',
  quickPay: '/quick-pay',
};
